import { AutoComplete } from "antd";
import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import classNames from "classnames";

import searchIcon from "../../assets/icon/searchIcon.svg";
import historyIcon from "../../assets/icon/history.svg";
import jobTitleIcon from "../../assets/icon/jobTitle.svg";
import companyIcon from "../../assets/icon/company.svg";
import hotIcon from "../../assets/icon/hot.svg";

import styles from "../style/index.module.scss";

type optionType = {
  label: any;
  value: string | number;
};

type propsType = {
  query: string;
  placeholder: string | React.ReactNode;
  setQuery: (value: string | number) => void;
  suggestionList: optionType[];
  onSearch?: (value?: string, optionType?: any) => void;
  inputOptions?: {
    companyGroupText: string;
    jobTitleGroupText: string;
    historyGroupText: string;
    popularJobsText?: string;
  };
  onFocus?: () => void;
};

function groupByType(data) {
  const grouped = data.reduce((acc, item) => {
    const type = item?.type || "local_history";

    if (!acc[type]) {
      acc[type] = [];
    }

    acc[type].push(item);

    return acc;
  }, {});

  return Object.keys(grouped).map((type) => ({
    type: type,
    list: grouped[type]
  }));
}

const Title = (props) => {
  const { type, inputOptions } = props;

  if (type == "hot_search") {
    return (
      <div className={styles.groupTitle}>
        <Image src={jobTitleIcon} alt={inputOptions.popularJobsText} width='17' height='17' />
        {inputOptions.popularJobsText}
      </div>
    );
  }

  if (type == "company") {
    return (
      <div className={styles.groupTitle}>
        <Image src={companyIcon} alt={inputOptions.companyGroupText} width='17' height='17' />
        {inputOptions.companyGroupText}
      </div>
    );
  }

  if (type == "job_title") {
    return (
      <div className={styles.groupTitle}>
        <Image src={jobTitleIcon} alt={inputOptions.jobTitleGroupText} width='17' height='17' />
        {inputOptions.jobTitleGroupText}
      </div>
    );
  }

  return (
    <div className={styles.groupTitle}>
      <Image src={historyIcon} alt={inputOptions.historyGroupText} width='17' height='17' />
      {inputOptions.historyGroupText}
    </div>
  );
};

function transformData(inputData, inputOptions) {
  return inputData.map((item) => ({
    label: <Title type={item.type} inputOptions={inputOptions} />,
    options: item.list || []
  }));
}

const InputInSearch = ({
  setQuery,
  query,
  placeholder,
  suggestionList,
  onSearch,
  inputOptions = {
    companyGroupText: "Company name",
    jobTitleGroupText: "Job title",
    historyGroupText: "Search history",
    popularJobsText: "Popular Jobs"
  },
  onFocus = () => {}
}: propsType) => {
  const autoCompleteRef = useRef();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(suggestionList);
  }, [suggestionList]);

  const handleSearch = (value: string) => {
    setQuery(value);
  };

  const handleEnter = (ev) => {
    if (ev.keyCode == 13 && onSearch) {
      onSearch();
      (autoCompleteRef.current as any)?.blur();
    }
    ev.stopPropagation();
    ev.preventDefault();
  };

  const handleSelect = (value, optionType) => {
    setQuery(value);
    onSearch(value, optionType);
    (autoCompleteRef.current as any)?.blur();
  };

  const groupedData = groupByType(options);
  const groupedOptions = transformData(groupedData, inputOptions);

  return (
    <div className={classNames(styles.autoComplete, "yolo-autocomplete-select")}>
      <AutoComplete
        getPopupContainer={(node) => node.parentElement || document.body}
        dropdownRender={(menu) => <div className='yolo-autocomplete-select-dropdown'>{menu}</div>}
        rootClassName={styles.customAutoComplete}
        ref={autoCompleteRef}
        onSearch={handleSearch}
        onSelect={handleSelect}
        placeholder={placeholder}
        value={query}
        options={groupedOptions}
        optionRender={(ev) => <ItemRender {...ev} />}
        onKeyUp={handleEnter}
        onFocus={onFocus}
      />
    </div>
  );
};

const ItemRender = (option) => {
  const { type, logo_url } = option.data;
  let icon = historyIcon;
  if (type == "company") {
    icon = logo_url;
  }
  if (type == "hot_search") {
    icon = hotIcon;
  }
  if (type == "job_title") {
    icon = searchIcon;
  }
  return (
    <div className={styles.autoCompleteItem}>
      {(type == "company" || type == "hot_search") && (
        <Image src={icon} alt='icons' width='17' height='17' />
      )}
      {option.label}
    </div>
  );
};

export default InputInSearch;
